import Splide from '@splidejs/splide';

const CaseView = {
  namespace: 'case',
  afterEnter({ next }) {
    const gallery = new Splide(next.container.querySelector('.splide'), {
      type: 'slide',
      speed: 350,
      easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      gap: 30,
      perMove: 1,
      autoWidth: true,
      trimSpace: true,
      focus: 'left',
      arrows: true,
      pagination: false,
      breakpoints: {
        768: {
          destroy: true // or 'completely'
        }
      }
    });

    /* Fix gallery offset on page load */
    gallery.on('mounted', function() {
      setTimeout(function() {
        gallery.options = { focus: 'center' };
      }, 250);
    });

    gallery.mount();
  }
};

export default CaseView;

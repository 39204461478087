const init = (page) => {
  const banner = page.querySelector('.banner');
  const bannerCloseButton = page.querySelector('.banner-close');

  const userClosedBanner = window.sessionStorage.getItem('closeBanner');
  if (banner && userClosedBanner) {
    banner.style.display = 'none';
  }

  bannerCloseButton.addEventListener('click', function() {
    banner.style.display = 'none';
    window.sessionStorage.setItem('closeBanner', true);
  });
};

export { init };

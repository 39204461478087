/**
 * © Antoine Puel — 2020
*/

/**
 * Import dependencies
*/
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import lazySizes from 'lazysizes';
import Rellax from 'rellax';

import * as Menu from './js/Components/Menu';
import FormInput from './js/Components/FormInput';
import * as Banner from './js/Components/Banner';
import { enterAnimation } from './js/Utils/Animations';

/**
 * Import Views
*/
import { copyEmail } from './js/Views/Global';
import CaseView from './js/Views/Case';
import ContactView from './js/Views/Contact';
import ContactFormView from './js/Views/ContactForm';
import EcosystemView from './js/Views/Ecosystem';
import MissionView from './js/Views/Mission';
import SolutionView from './js/Views/Solution';

/**
 * LazySizes Options
*/
lazySizes.cfg.lazyClass = 'lazy';

/**
 * Barba Options
*/
barba.hooks.beforeEnter(({ next }) => {
  window.scrollTo(0, 0);
  document.body.style.overflowY = '';
  Menu.init(next.container);
  FormInput.init(next.container);
  Banner.init(next.container);

  copyEmail();
  enterAnimation('.animate-up');
  enterAnimation('.animate-down');
  enterAnimation('.animate-left');
  enterAnimation('.animate-right');

  const rellax = new Rellax('.rellax');
});

barba.use(barbaPrefetch);
barba.init({
  transitions: [{
    once({ next }) {
      // Play the fading animation at first page load
      const currentContainer = next.container;
      const dynamicContent = currentContainer.querySelector('.dynamic-content');
      dynamicContent.classList.add('enter');
    },
    enter({ next }) {
      const currentContainer = next.container;
      const dynamicContent = currentContainer.querySelector('.dynamic-content');
      // Wait one frame before adding the class
      setTimeout(() => dynamicContent.classList.add('enter'), 0);
    }
  }],
  views: [
    ContactView,
    ContactFormView,
    MissionView,
    CaseView,
    EcosystemView,
    SolutionView
  ]
});
